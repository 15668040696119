import * as React from 'react';
import ClassificationNodeForm from "../../forms/ClassificationNodeForm";
import classNames from 'classnames';
import ActionIcon from "../ActionIcon";
import { i18n } from "../../config/i18n";
import Errors from "./Errors";
import {LocaleContext} from "../../contexts/LocaleContext";
import { linkifyLogic } from '../../utils/medcodelogic'
import {useContext} from "react";

export interface INode {
    parent_id: string,
    logic: string,
    children: [],
    chapter_entry: boolean,
    is_goto: boolean,
    compiled_logic: string,
    editable: boolean,
    id: string,
    classname: string,
    description: string,
    chapter_code: string,
    errors?: { msg: string }[]
}

interface Props {
    node: INode,
    changeNode?: any;
    removeNode?: any;
    addNode?: any;
    updateNode?: any;
    id?: string;
    isRequesting?: boolean;
    multilabel: boolean;
}

const Node: React.FunctionComponent<Props> = (props) => {
    const node = props.node;
    const isLeafNode = !node.logic && node.children && node.children.length === 0;
    const nodeClass = node.chapter_entry ? 'bg-lightred' :
        (isLeafNode ? (node.is_goto ? 'bg-lightgreen' : 'bg-lightblue') : 'bg-light');
    const compiledLogic = {__html: linkifyLogic(node.compiled_logic)};
    const croppedClassname = (node.classname != null && node.classname.length > 28) ?
        node.classname.substring(0,25) + ' ...' : node.classname
    const isStartNode = props.node.parent_id == null

    const locale = useContext<string>(LocaleContext)

    return (
        <div id={`noanchor_node_${node.id}`}>
            <div className={classNames('card card-body', nodeClass, {'card-expanded': node.editable})}
            >
                {node.editable ?
                    <ClassificationNodeForm
                        values={node}
                        onCancel={() => props.changeNode({editable: false})}
                        onSubmit={props.updateNode}
                        patient_classification_system_id={props.id}
                    /> :
                    <>
                        <div className="row">
                            <div className="col-lg-9">
                                {isLeafNode ?
                                    <h5>
                                        <div className="badge bg-secondary">{croppedClassname}</div>
                                    </h5>
                                    :
                                    <div className="compiledLogic" dangerouslySetInnerHTML={compiledLogic}/>
                                }
                            </div>
                            <div className="col-lg-3 text-end fs-5">
                                { !isStartNode &&
                                <>
                                    <ActionIcon
                                        disabled={props.isRequesting}
                                        icon="pencil"
                                        title={i18n.t('edit')}
                                        onClick={() => props.changeNode({editable: true})}
                                    />

                                    <ActionIcon
                                        disabled={props.isRequesting}
                                        icon="trash-o"
                                        title={i18n.t('delete')}
                                        onClick={() => {if(confirm(i18n.t('confirm_delete_node'))){props.removeNode()}}}
                                    />
                                </>
                                }
                                { (isStartNode || !isLeafNode) &&
                                <ActionIcon
                                    disabled={props.isRequesting}
                                    icon="plus-circle"
                                    title={i18n.t('add_node')}
                                    onClick={() => props.addNode(node.id)}
                                />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 logicDescription">
                                {node.chapter_entry &&
                                    <div
                                        className="icon font-weight-bold"
                                        title={i18n.t('goto_chapter')}>
                                        <a
                                            href={`/classification_systems/${props.id}/logic?chapter_node_id=${node.id}&amp;locale=${locale}`}>
                                            <i className="fa fa-sitemap action action-add"/>
                                            {node.chapter_code}
                                        </a>
                                    </div>
                                }
                                {node.description}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 text-danger">
                                <Errors errors={props.node.errors}/>
                            </div>
                            {node.id &&
                            <div className="col-lg-6 text-end fs-5">
                                {!!node.logic && !props.multilabel && <ActionIcon
                                  icon="balance-scale"
                                  title={i18n.t('compare_dec_node')}
                                  target="_blank"
                                  href={`/pcs_nodes/${node.id}/compare?locale=${locale}`}
                                />}
                                <ActionIcon
                                  icon="pie-chart"
                                  title={i18n.t('summarize')}
                                  target="_blank"
                                  href={`/pcs_nodes/${node.id}/summarize?locale=${locale}`}
                                />
                            </div>}
                        </div>
                    </>}
            </div>
        </div>
    );
};

export default Node
